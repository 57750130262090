// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var StoryCardContainer = "R_cJ";
export var cardContent = "R_bk";
export var cardTitle = "R_cK";
export var wpPostImage = "R_cL";
export var wpPostImageContainer = "R_cM";
export var cardByline = "R_cN";